<script setup>
import { Link, useForm } from "@inertiajs/vue3";

import Button from "@/Components/Button/Button.vue";
import Footer from "@/Components/Footer/FooterLogin.vue";
import { mapErrors } from "@/utilities";
import { inject } from "vue";

import GuestLayout from "@/Layouts/GuestLayout.vue";
let route = inject("route");

defineProps({
  canResetPassword: Boolean,
  status: String
});

const form = useForm({
  email: "",
  password: "",
  remember: false
});

const submit = () => {
  form
    .transform(data => ({
      ...data,
      remember: form.remember ? "on" : ""
    }))
    .post(route("login"), {
      onFinish: () => form.reset("password"),
      onError: errors => (form.errors = mapErrors(errors))
    });
};
</script>

<template>
  <GuestLayout title="Log in">
    <div class="container flex flex-col items-center w-full relative -top-[200px]">
      <div class="w-full p-8 bg-white rounded-2xl max-w-[550px] text-blue">
        <h1 class="text-[32px] font-bold mb-2">{{ $t('Login') }}</h1>
        <p
          class="text-base mb-9"
        >{{ $t('Enter your credentials to access your Flexable account.') }}</p>
        <form @submit.prevent="submit">
          <div class="grid gap-y-2">
            <FormKit
              type="email"
              name="email"
              :label="$t('Email')"
              v-model="form.email"
              :errors="form.errors.email"
              :value="form.email"
              validation="required|email"
            />
            <FormKit
              type="password"
              name="password"
              :label="$t('Password')"
              v-model="form.password"
              :errors="form.errors.password"
              :value="form.password"
              validation="required"
            />

            <FormKit
              type="checkbox"
              name="remember"
              :label="$t('Remember me')"
              v-model="form.remember"
              :errors="form.errors.remember"
              :value="form.remember"
            />
          </div>

          <div class="w-full mt-4 mb-4 text-xs">
            <Link
              class="inline-block transition-colors duration-300 hover:text-pink"
              :href="route('password.request')"
            >{{ $t('Forgot your password?') }}</Link>
          </div>

          <Button full-width :processing="form.processing">{{ $t('Log in') }}</Button>

          <div class="w-full mt-8 text-sm text-center">
            {{ $t("Don't have an account?") }}
            <Link
              class="inline-block font-bold transition-colors duration-300 hover:text-pink"
              :href="route('register')"
            >{{ $t('Register now') }}</Link>aaaaa
          </div>
        </form>
      </div>
      <Footer />
    </div>
  </GuestLayout>
</template>
